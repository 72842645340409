import { Loading } from "../Loading"

export const LoadingHome = () => {
    return (
        <>
            <section className="loading-home">
                <Loading />
                <img src="/logovertical.png"></img>

            </section>
        </>
    )
}